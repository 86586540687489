<template>
    <div
            class="goodsV1"
            v-debounce="[
      'click',
      () => {
        onClick();
      },
    ]"
    >
        <img :src="url" />
        <div class="text-ellipsis1">{{ title }}</div>
        <div>
            <span>¥{{ market_price }}</span>
            <span>¥{{ product_price }}</span>
        </div>
        <img
                v-if="mail_type == 1"
                :src="require('@STATIC/image/icons/ziti.png')"
                class="goodsV1_tip"
        />
    </div>
</template>

<script>
    export default {
        name: "goodsInfo",
        props: {
            //1是快递 2是自提
            mail_type: {
                type: [Number, String],
                default: "",
            },
            url: {
                type: String,
                default: "",
            },
            title: {
                type: String,
                default: "",
            },
            product_price: {
                type: [String, Number],
                default: "",
            },
            market_price: {
                type: [String, Number],
                default: "",
            },
            param: null,
        },
        methods: {
            onClick() {
                this.$emit("onClick", this.$props.param);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @include b(goodsV1) {
        position: relative;
        box-sizing: border-box;
        width: 103px;
        margin-left: 5px;
        margin-right: 5px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        & > img {
            width: 103px;
            height: 103px;
            border-radius: 3px;
        }
        @include e(tip) {
            position: absolute;
            width: 39px !important;
            height: 39px !important;
            border-radius: 0 !important;
            right: 0;
            top: 0;
        }
        & > div {
            &:first-of-type {
                margin-top: 8px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #303030;

            }
            &:last-of-type {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                & > span:first-of-type {
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 800;
                    color: #E40000;
                    line-height: 30px;
                }
                & > span:last-of-type {
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #979797;
                    text-decoration: line-through;
                }
            }
        }
    }
</style>
