<template>
    <div v-if="menuList.length>0">
        <nut-swiper
                direction="horizontal"
                :loop="true"
                :canDragging="true"
                :paginationVisible="getPageShow()"
        >

            <div  v-for="(item,index) in menuList" :key="index"  class="nut-swiper-slide ">
                <div class="menu-big-box">
                    <div @click="itemOnClick(itemSon,indexSon)" v-for="(itemSon,indexSon) in item" :key="indexSon"class="menu-big-sonbox">
                        <img class="nu-big-sonbox-img" :src="itemSon.cover">
                        <div class="nu-big-sonbox-text">{{itemSon.catalog_name}}</div>
                    </div>
                </div>
            </div>

        </nut-swiper>
    </div>

</template>

<script>
    export default {
        name: "pageMenu1",
        props: {
            list: {
                type: Array,
                default () {
                    return []
                },
            },
        },
        methods: {
            itemOnClick(itemSon,indexSon){
                this.$emit('onClick', {item: itemSon, index: indexSon})
            },
            getPageShow(){
                 if (this.menuList.length > 1){
                     return true;
                 }else{
                     return false;
                 }

            }
        },
        created() {
            console.log(this.$props)
            this.menuList =JSON.parse(JSON.stringify(this.$props.list))
        },

        watch:{
             list (newV, oldV) {
                this.menuList = JSON.parse(JSON.stringify(newV))
            },
        },
        data () {
            return {
                menuList:[]
            }
        },
    }

</script>

<style scoped>
    .menu-big-box{
        border:0px solid #ccc; height:181px;
        display:flex;
        flex-wrap: wrap;
        justify-content: flex-start;

    }
    .menu-big-sonbox {
        border: 0px solid #ccc;
        height: 181px;
        width: 66px;
        height: 78px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align:center;

    }
    .nu-big-sonbox-img{
        width: 50px;
        height: 50px;
        border-radius: 18px;
        margin:auto;
    }
    .nu-big-sonbox-text{
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #303030;
        line-height: 24px;
    }
    /deep/ .nut-swiper .nut-swiper-pagination .swiper-pagination-bullet.active {
        background-color: #F46A17;
        opacity: 1;
    }

</style>