<template>
  <div class="menu">
    <gridV1 :row="realRow" :col="col" :rowGap="rowGap" :colGap="colGap">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        :class="{ hide: index >= 10 }"
        v-debounce="[
          'click',
          () => {
            itemOnClick(item, index);
          },
        ]"
      >
        <img :src="item.cover" />
        <span>{{ item.catalog_name }}</span>
      </div>
    </gridV1>
  </div>
</template>
<script>
import gridV1 from "@/uicomponent/grid/gridV1.vue";
export default {
  components: {
    gridV1,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    row: {
      type: Number,
      default: 2,
    },
    col: {
      type: Number,
      default: 5,
    },
    colGap: {
      type: String,
      default: "26px",
    },
    rowGap: {
      type: String,
      default: "10px",
    },
  },
  data() {
    return {
      realRow:1
    };
  },
  created(){
    this.realRow = Math.ceil(this.$props.list.length / this.$props.col)
  },
  methods: {
    itemOnClick(item, index) {
      this.$emit("onClick", { item: item, index: index });
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  width: 100%;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > img {
    width: 37px;
    height: 37px;
  }
  & > span {
    margin-top: 12px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f4f;
  }
}
</style>
