<template>
    <div class="gui-flex gui-rows gui-space-between">
        <div class="gui-waterfall-item" v-for="(lists, index1) in productList" :key="index1">
            <div :class="['gui-waterfall-items-'+ index1]">
                <div class="gui-waterfall-items" v-for="(products, index2) in lists" :key="index2"
                     @click="goToGoodsDetail(index1,index2)">

                    <div class="outer">
                        <img
                                v-if="products.stock_number==0"
                                :src="require('@STATIC/image/icons/zero.png')"
                                class="goodsV1_zero"
                        />
                        <div class="inner">
                            <img class="gui-waterfall-items-image" :src="products.cover" mode="widthFix"></img>
                            <div class="goods-title">{{products.title}}</div>
                            <div class="goods-line gui-flex gui-rows">
                                <div class="goods-price">
                                    <div class="goods-market-price">
                                        <div class="rmb">¥</div>
                                        <div class="market-price">{{products.market_price}}</div>
                                    </div>
                                    <div class="goods-delete-price">
                                        ¥{{products.product_price}}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
  var leftHeight = 0
  var rightHeight = 0
  export default {
    name: 'goodsCurrent',
    data () {
      return {
        productList: [
          [],
          []
        ],
        leftHeight: 0,
        rightHeight: 0
      }
    },
    props: {
      goodsList: {
        type: Array,
        default: () => {
          return []
        }
      },

    },
    watch: {
      goodsList (newV) {
        let lArr = []
        let rArr = []
        for (var i = 0; i < newV.length; i++) {
          if (i % 2 == 0) {
            lArr.push(newV[i])
          } else {
            rArr.push(newV[i])
          }
        }
        this.productList = [lArr, rArr]
      },
      leftHeight (newV) {
        let rightHeight = this.rightHeight
        console.log(newV)
        console.log(rightHeight)
        if (rightHeight > 0 && newV > 0 && newV - rightHeight > 600) {
          let data = this.productList[0].pop()
          this.productList[1].push(data)
        }
      }
    },
    methods: {
      rebuildData () {
        const query = uni.createSelectorQuery().in(this)
        query.select('.gui-waterfall-items-0').boundingClientRect(data => {
          this.leftHeight = data.height
        }).exec()

        query.select('.gui-waterfall-items-1').boundingClientRect(data2 => {
          this.rightHeight = data2.height
        }).exec()
      },
      goToGoodsDetail (index1, index2) {
        let goodsId = this.productList[index1][index2].id
        if (this.$platform.wxsdk.isWechat()) {
          let query = {
            goodsId: goodsId,
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/community/goods-detail?query=' + query,
          })
        } else {
          this.$router.push({
            path: 'goods-detail',
            query: {goodsId: goodsId},
          })
        }
      }
    }
  }

</script>

<style scoped>
    .gui-flex {
        display: flex;
    }

    .gui-rows {
        flex-direction: row;
    }

    .gui-space-between {
        justify-content: space-between;
    }

    .gui-waterfall-item {
        width: 168px;
    }

    .outer {
        background-color: #FFFFFF;
    }

    .inner {

        padding-top: 3px;
        padding-bottom: 10px;
        box-shadow: 0px 0px 10px 0px #ECEAEA;
    }

    .gui-waterfall-items {
        margin-bottom: 3px;
        border-radius: 8px;
        overflow: hidden;
        padding: 5px 0 6px 0;
        display: block;
        position: relative;
    }

    .mask {
        background: #EDEDED;
        border-radius: 8px;
        opacity: 0.5;
        border: 0px solid red;
        position: absolute;
        z-index: 2;
        width: 100%;
        text-align: center;
        left: 0px;
        top: 0px;
        height: 100%;
        display: flex;

    }

    .mask-big-circle {
        margin: auto;
        width: 85px;
        height: 85px;
        border-radius: 50%;
        opacity: 1;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
    }

    .mask-small-circle {
        width: 73px;
        height: 73px;
        border: 2px solid #EDEDED;
        border-radius: 50%;
        margin: auto;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 63px;
        text-align: center;
    }

    .gui-waterfall-items-image {
        margin: 9px 0px 0 9px;
        width: 150px;
        display: block;

    }

    .goods-title {
        padding: 0 10px;
        font-size: 14px;
        margin-left: 0px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #303030;
        margin-top: 6px;
    }

    .goods-line {
        padding: 0 10px;
        display: flex;
        align-items: center;
        margin-top: 6px;
    }

    .goods-price {
        flex: 1;
        display: flex;
        align-items: flex-end;
    }

    .goods-market-price {
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #F46A17;
        display: flex;
        align-items: flex-end;
        margin-right: 3px;
    }

    .rmb {
        font-size: 10px;
    }

    .market-price {
        flex: 1;
        display: flex;
        align-items: flex-end;
        font-size: 13px;
    }

    .goods-delete-price {
        font-size: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #BFBFBF;
        text-decoration: line-through;
    }

    .goods-add-cart {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
    }

    image {
        width: 22px;
        height: 22px;
    }

    .goodsV1_zero {
        position: absolute;
        width: 56px !important;
        height: 45px !important;
        border-radius: 0 !important;
        right: 0;
        top: -1px;
    }


</style>